<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="dataTable"
            :search=search
            :loading=loadingTable
            :disable-sort="$vuetify.breakpoint.name == 'xs'"
            :key="`list-groups-${tenantId}`"
            :items-per-page=15
            :options.sync="options"
            loading-text="Cargando grupos..."
            no-results-text="No se han encontrado grupos"
            no-data-text="Sin grupos"
            class="elevation-1"
            dense
        >
            <template v-slot:top>
                <v-row 
                    align="center"
                    justify="center"
                >
                    <v-col cols=12 md=3  class='text-center'>
                        <v-toolbar-title>
                            <v-icon>mdi-account-switch</v-icon>GRUPOS DEL SISTEMA
                        </v-toolbar-title>
                    </v-col>
                    <v-col md=6>
                        <v-text-field
                            v-model=search
                            label=Buscar
                        />
                    </v-col>
                    <v-col md=3 class='text-center'>
                        <v-btn 
                            small
                            color = 'primary'
                            @click=editGroup
                        > 
                            <v-icon>mdi-plus</v-icon> Nuevo grupo
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:item.status="{ item }">
                {{item.status != null ? item.status.toUpperCase(): ''}}
            </template>
            <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn  
                            v-bind="attrs"
                            v-on="on" 
                            icon 
                            @click="editGroup(item.party_id)"
                        >
                            <v-icon color='orange'>mdi-account-edit</v-icon>
                        </v-btn>
                    </template>
                    <span>Editar</span>
                </v-tooltip>                
            </template>
        </v-data-table>
        <PartyComponent 
            v-if=dialog
            :availableItems=availableItems
            :dialog=dialog
            :dataParty=dataParty
            @closeDialog="dialog= !dialog"
            @savedNewParty=savedNewParty
        />
    </div>
</template>

<script>

import {mapState, mapMutations, mapActions} from 'vuex'
import PartyComponent from '../general/PartyComponent'

export default {
    name: 'ListPartyGroupsComponent',
    components:{
        PartyComponent
    },
    data:()=>({
        dataTable:[],
        setfilters:[],
        dialog:false,
        search:'',
        dataParty:{},
        availableItems:{},
        options: {},
        defaultAvailableItems:{
            workTeam:true,
            assignWorkEfort:true
        },
        headers:[
            {text: 'Código', align: 'start', value: 'party_id'},
            {text: 'Nombre', align: 'start', value: 'name'},
            {text: 'Estado', align: 'start', value: 'status'},
            {text: 'Comentarios', align: 'start', value: 'comments'},
            {text: 'Actions', align: 'start', value: 'actions',align:'center'}
        ]
    }),
    watch:{
        options: {
            handler () {
                this.getGroups()
            },
            deep: true,
        },
    },
    computed:{
        
        ...mapState('master',['loadingTable','tenantId'])

    },
    methods:{

        ...mapMutations('master',['setUrl','setLoadingTable','setTitleToolbar']),

        ...mapActions('master',['requestApi']),

        getGroups(){

            this.setLoadingTable(true)
           
            this.dataTable=[]
            
            this.setUrl('lista-partys')
            this.requestApi({
				method: 'GET',
				data :{
                    typeList: 'groups'
                }
			}).then(res =>{
                console.log(res)
                this.totalItems = res.data.page_count
                this.dataTable = res.data._embedded.lista_partys
			}).then(()=>{
                this.setLoadingTable(false)
            })
        },

        editGroup(party_id){
           
            let noPartyId = typeof party_id =='object'

            this.setUrl(`lista-partys/${noPartyId ? 0 :party_id}`)
            console.log(noPartyId)
            this.requestApi({
                method: 'PATCH',
                data :{}
            }).then(res =>{

                console.log(res)
                this.dataParty = res.data
                this.availableItems = noPartyId ? { workTeam:true } : this.defaultAvailableItems
                this.dialog=true

            }).then(()=>{
                this.setLoadingTable(false)
            })
                       
        },

        savedNewParty(data){
            this.availableItems= this.defaultAvailableItems
            if(data.newParty){
                this.dialog=false
                this.editGroup(data.partyId)
            }
            this.getGroups(this.filters)
        },

    },
    mounted(){
        this.setTitleToolbar()
    }
}
</script>

<style>
    .v-dialog{
        background-color: white;
    }
    .v-divider--inset{
        max-width: 100%!important;
        margin-left:10px!important;
        border-color: rgb(212 212 212)!important;
    }
</style>